import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import { Link } from "gatsby";
import { render } from 'react-dom';
import ReactRoundedImage from "react-rounded-image";
import Jojo from "../1Pics/jojo.jpg";
import Marki from "../1Pics/marki.jpg";
import { SocialIcon } from 'react-social-icons';
import Divider from '@material-ui/core/Divider';
import {Helmet} from "react-helmet";

export default () => (
    <Layout>
  <Helmet>
      <meta charSet="utf-8" />
      <title>Über Uns</title>
      
  </Helmet>

      <section id="blog" className="pt-20 md:pt-20">
    <div className="container mx-auto px-8 lg:flex">
      <div className="text-center lg:text-left">
        <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
         Über uns
        </h1><br/>
        <p className="text-l"> 
          <p align="justify">Entrümplung, Abfall, Müll, Schrott, Entsorgung. Und das am Besten sofort. - Ziemlich unsexy Themen.</p><br/>
          
          <p><strong>Mit re.cycular wollen wir ein ganz alltägliches Probleme lösen.</strong> </p><br/>
          
          <p align="justify">Viel zu oft stehen ausrangierte Gegenstände wahllos vor Häusern, Einfahrten, 
          irgendwo in der Stadt oder werden fälschlicherweise in den Hausmüll geschmissen. 
          Wir glauben nicht, dass unsere Mitmenschen dies böswillig tun. Wir glauben nur, dass es noch keinen Service gibt, 
          der gut und einfach genug ist, um dieses Problem auf eine Art zu lösen, die vom Kunden wirklich angenommen wird.
          Mit re.cycular wollen wir die Welt etwas <strong>einfacherer </strong> und <strong>sauberer</strong> machen. re.cycular ist eine 
          Plattform zur Buchung von innerstädtischen Sperrmüllabholungen, die sich durch Einfachheit und Benutzerfreundlichkeit 
         auszeichnet. </p> <br/>
       
        <p><strong>Unsere Werte</strong></p> <br/>
        <p align="justify"> <strong>Customer-Centricity:</strong> re.cycular ist Deine Plattform zur Entsorgung von Sperrmüll, die sich 
        maximal an Deinen Bedürfnisse ausrichtet und den gesamten Prozess zeitgemäß vereinfacht. </p> <br/>
        <p align="justify"> <strong>Fellowship:</strong> re.cycular tritt nicht in Konkurrenz zum bestehenden Ökosystem. Durch unser Partnerprogramm 
        bieten wir allen Akteuren der Branche Zugriff auf unsere Plattform und eröffnen damit neue Möglichkeiten. </p> <br/>
        <p align="justify"> <strong>Eco-Awareness:</strong> recycular bietet jedem die einfachste Möglichkeit, seinen Beitrag zum Umweltschutz und einer 
        sauberen Innenstadt zu leisten. Außerdem gewährleistet re.cycular eine 100% CO2-neutrale Abholung und 
        sachgerechte Entsorgung / Wiederverwertung. </p> <br/><br/><br/>
       
        <p><strong>Das Team</strong></p> 
        </p>
        <SplitSection id="Team"
      primarySlot={
        <div >
        <ReactRoundedImage image={Jojo} roundedColor="#4199E1" roundedSize="13" imageWidth="150" imageHeight="150" />
        <p className="text-xl text-left mt-6">
        <strong>Johannes Felber </strong></p>
        <p>Co-Founder</p><br/>
        <SocialIcon url="https://linkedin.com/in/johannes-felber-648263185/" target="_blank"/>
        </div>
      }
      secondarySlot= {<div >
        <ReactRoundedImage image={Marki} roundedColor="#4199E1" roundedSize="13" imageWidth="150" imageHeight="150" />
        <p className="text-xl text-left mt-6">
        <strong>Markus Werra</strong></p>
        <p>Co-Founder</p><br/>
        <SocialIcon url="https://linkedin.com/in/mwerra/" target="_blank"/>
        </div>
    }
    />
      </div>
  
   
    </div>
        </section>
  </Layout>
  );